import { HASHTAGS } from "../../../type/EnumHashtags";
import { BRAND } from "../../../type/EnumBrand";
import { IProductData } from "../../../type/IProductData";

export const pandora: IProductData = {
  id: "wesaudio-pandora",
  name: "WesAudio _Pandora",
  description: "Stereo Multi-Band compressor with digital recall",
  images: ["/img/wesaudio_pandora_front.jpg", "/img/wesaudio_pandora_side.jpg", "/img/wesaudio_pandora_angle.jpg"],
  details: [{
    label: "Description",
    markdown: `
Fully analog, stereo multi-band compressor with digital recall.

For years, multiband compressors have been revered for their ability to finely control dynamic ranges across different frequencies, shaping the balance and punch of countless iconic productions.   
_Pandora takes this to the next level, delivering an unparalleled analog compression experience seamlessly integrated with modern digital recall and automation.   
With its precise multiband control and intuitive digital interface, _Pandora is poised to become a cornerstone in studios worldwide, 
offering the perfect fusion of analog warmth and contemporary convenience.   
Experience the future of compression   
– the classic depth of analog sound with the flexibility and power of digital technology in _Pandora.
`
  }, {
    label: "Key Features",
    markdown: `
### _PANDORA stereo multi-band compressor
- Purely Analog: 3-band multiband compressor with 24dBu headroom.
- 3-Band Control: Crossover filters from 40Hz to 15kHz for precise processing.
- THD: Tailored distortion per band, optimized by frequency.
- Flexible VCA Compression: Independent compressors for each band.
- Low Band SC Filters: 3 sidechain high-pass filters for low-frequency control.
- High Band SC Filters: 3 sidechain low-pass filters for precise triggering.
- Parallel Compression: MIX knob for blending dry and compressed signals.
- Gain reduction metering on both the hardware and plugin for each band, with input metering for each band in the plugin.
- Modern GUI: Full control over hardware via plugin interface.
- Accurate Metering: GR metering on hardware and plugin, input metering in plugin.
- Compatible with 500 series and ng500 series (e.g., _TITAN).
- Total Recall and plugin control, compatible with most DAWs.
- Supports analog automation within DAWs for seamless integration.
- Occupies 3 slots in the 500 series format but provides purely stereo processing, utilizing only slots 1 and 2 for audio routing.
`
  }, {
    label: "Specifications",
    markdown: `
- Frequency response	20Hz-20kHz (0.2dB)
- THD+N (WET)	0.009% (1kHz,0dBu)
- THD+N (DRY)	0.005% (1kHz,0dBu)
- Input impedance	10kohm
- Output impedance	< 100ohm
- Max signal level	+24dBu
- Crosstalk	(1kHz) < -110dB
- Attack	0.1, 0.3, 1, 3, 10, 30 (ms)
- Release	0.1, 0.3, 0.6, 0.9, 1.2, Auto (ms)
- Ratio	1.5, 2.4, 10
- SC HPF Filter LOW	60, 90, 150 (Hz)
- SC LPF Filter HI	3, 6, 9 (kHz)
- Format	3 slots stereo 500 series module
- Power consumption	160mA/+16V and 140mA/-16V (per slot)
- Unit dimensions	115x133x158 mm
- Box dimensions	118x162x234 mm
- Unit weight	1 kg
- Box weight	1,2 kg
- Warranty	2 years
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/_pandora/" target="_blank">https://wesaudio.com/product/_pandora/</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.MULTIBAND_COMP, HASHTAGS.TRIPLE_SLOT, HASHTAGS.NEXT_GEN, HASHTAGS.MIXING, HASHTAGS.MASTERING, HASHTAGS.LIVE_SOUND, HASHTAGS.API_500],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: ["https://www.youtube.com/embed/yb696ia3rEU?si=9r8b5SF-TasgjGiO", "https://www.youtube.com/embed/-lc4WVXmpRg?si=OTN2tv1UZDnNueZ-", "https://www.youtube.com/embed/AQa0mmrlBRo?si=eDih_ez9yzOWVgSC"]
}