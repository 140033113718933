import { BRAND } from "../../../type/EnumBrand";
import { HASHTAGS } from "../../../type/EnumHashtags";
import { IProductData } from "../../../type/IProductData";

export const ngTubeComp: IProductData = {
  id: "wesaudio-ngtubecomp",
  name: "WesAudio NGTUBECOMP",
  description: "Next Gen Tube Compressor",
  images: ["/img/wesaudio_ngtubecomp_front.png", "/img/wesaudio_ngtubecomp_side.png", "/img/wesaudio_ngtubecomp_plugin.jpg", "/img/wesaudio_ngtubecomp_inside.jpg"],
  details: [{
    label: "Description",
    markdown: `
For decades, vari-mu compressors have been revered for their smooth, musical compression and ability to add warmth and character to any recording.  
Our ngTubeComp builds on this iconic legacy, delivering an exquisite analog compression experience with the precision and convenience of modern digital recall.  
Whether you’re working in dual mono, stereo, or mid-side mode, the ngTubeComp provides unparalleled versatility and control.  
Embrace the perfect balance of timeless tonal richness and cutting-edge functionality, making the ngTubeComp an essential tool for shaping your sound with authenticity and ease.  
Experience analog craftsmanship, redefined for the digital age, with ngTubeComp.
`
  }, {
    label: "Key Features",
    markdown: `
### Main Features
- Purely analog compressor with a 26dBu headroom, delivering authentic vari-mu tube compression for rich, musical dynamics.
- Three operation modes: DUAL for independent channels, STEREO for balanced tracks, and MID-SIDE for precise spatial adjustments.
- Built with 6 tubes and 4 transformers for exceptional tonal character and reliability.
- Dual output options: Tube + Carnhill transformer with IRON PAD for warmth, or electronically balanced for a cleaner sound.
- Integrated tube saturation circuit for adding lush harmonic depth directly from the compression path.
- Adjustable Total Harmonic Distortion (THD) for sound shaping, from subtle enhancement to bold coloration.
- Precision control with variable attack and release for versatile compression behavior.
- Side chain features include a high-pass filter, high-shelf boost, and listen mode for refined EQ adjustments.
- Multiple compression modes: Feedbackward for classic analog warmth, Feed-Forward for modern precision, and Limit Mode for tight dynamics.
- Digital integration enables seamless DAW/live plug-in control for effortless recall and enhanced workflow.
`
  }, {
    label: "Manufacturer Links",
    markdown: `
- <a to="https://wesaudio.com/product/ngtubecomp" target="_blank">https://wesaudio.com/product/ngtubecomp</a>
    `
  }],
  metadata: {
    hashtags: [HASHTAGS.COMPRESSOR, HASHTAGS.NEXT_GEN, HASHTAGS.MASTERING, HASHTAGS.MIXING, HASHTAGS.LIVE_SOUND, HASHTAGS.THREE_U, HASHTAGS.RACK],
    manufacturer: BRAND.WESAUDIO,
  },
  videos: [
    "https://www.youtube.com/embed/DOGF026jzkk?si=Vmea4qHvrz4ca8PT",
  ]
}