import { rhea } from "../data/products/wesaudio/rhea";
import { dione } from "../data/products/wesaudio/dione";
import { hyperion } from "../data/products/wesaudio/hyperion";
import { mimas } from "../data/products/wesaudio/mimas";
import { titan } from "../data/products/wesaudio/titan";
import { phoebe } from "../data/products/wesaudio/phoebe";
import { ngbuscomp } from "../data/products/wesaudio/ngbuscomp";
import { odd } from "../data/products/purpleAudio/5E1Odd";
import { action } from "../data/products/purpleAudio/5C1Action";
import { mc77 } from "../data/products/purpleAudio/mc77";
import { biz } from "../data/products/purpleAudio/5M1Biz";
import { pants } from "../data/products/purpleAudio/5M2Pants";
import { sweetTenRack } from "../data/products/purpleAudio/sweetTen";
import { ng76 } from "../data/products/wesaudio/ng76";
import { distressorel8xPair } from "../data/products/empiricalLabs/distressorel8xPair";
import { elx7Fatso } from "../data/products/empiricalLabs/elx7Fatso";
import { derrEsser } from "../data/products/empiricalLabs/derrEsser";
import { docDerr } from "../data/products/empiricalLabs/docDerr";
import { vivid } from "../data/products/mother/vivid";
import { wai2950 } from "../data/products/mother/wai2950";
import { kamaya } from "../data/products/mother/kamaya";
import { pump } from "../data/products/empiricalLabs/pump";
import { mythvca500 } from "../data/products/tegeler/mythvca500";
import { ngTubeEq } from "../data/products/wesaudio/ngTubeEQ";
import { ngTubeComp } from "../data/products/wesaudio/ngTubeComp";
import { pandora } from "../data/products/wesaudio/pandora";

export const featuredProductData = [
  kamaya,
  wai2950,
  vivid,
  pump,
  phoebe,
  dione,
  hyperion,
  ngbuscomp,
  ngTubeEq,
  ngTubeComp,
  pandora,
  elx7Fatso,
  distressorel8xPair,
  mythvca500,
  mc77,
  ng76,
  rhea,
  mimas,
  action,
  biz,
  pants,
  odd,
  derrEsser,
  docDerr,
  titan,
  sweetTenRack,
];
